
import { Options, Vue } from "vue-class-component";
import liff from "@line/liff";
import { signInWithIdAndPassword } from "@/api/auth";
import MCommentPanel from "@/components/MCommentPanel.vue";
import MButton from "@/components/form/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import store from "@/store";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MCommentPanel,
    MButton,
    MTextField
  }
})
export default class Login extends Vue {
  loginId = "";
  enteredLoginId = false;
  password = "";
  enteredPassword = false;

  get validLoginId(): boolean {
    return (
      /^[\x21-\x7E]+$/.test(this.loginId) && String(this.loginId).length >= 5
    );
  }

  get validPassword(): boolean {
    return /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(this.password);
  }

  get validData() {
    return this.validLoginId && this.validPassword;
  }

  get allEntered(): boolean {
    return this.enteredLoginId && this.enteredPassword;
  }

  allEnter() {
    this.enteredLoginId = true;
    this.enteredPassword = true;
  }

  async signIn() {
    this.allEnter();

    if (!this.validData) {
      return;
    }

    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ログイン処理中...");

    let token: string | undefined;
    if (store.state.isInLiff) {
      if (!liff.isLoggedIn()) {
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
        alert("LINEのログインが完了していません。アプリを開き直してください。");
        return;
      }
      const accessToken = liff.getAccessToken();
      token = accessToken ? accessToken : undefined;
    }

    try {
      await signInWithIdAndPassword(this.loginId, this.password, token);
    } catch (e) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      if (e.code === "not-found") {
        alert(
          "ログインIDに一致するユーザーが見つかりませんでした。\n入力したログインIDをご確認の上で再度実行してみてください。"
        );
      } else if (e.code === "permission-denied") {
        alert(
          "パスワードが間違っています。\n入力したパスワードをご確認の上で再度実行してみてください。"
        );
      } else {
        alert(`ログインに失敗しました\n\n${e}`);
        await saveErrorLog(
          store.state.student,
          e.code,
          e.message,
          "Failed to sign in"
        );
      }
      return;
    }

    store.commit("SET_LOADING", false);
    store.commit("SET_LOAD_TEXT", "");
    const path = store.state.redirect;
    store.commit("SET_REDIRECT", "/");
    this.$router.replace(path);
  }

  created() {
    store.commit("SET_LOADING", false);
    store.commit("SET_LOAD_TEXT", "");
  }
}
